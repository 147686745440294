import type {
    Membership,
    MembershipPermissionsType,
    MembershipResourceCrudPermission
} from '~/types';
import { useCustomAuth } from '~/composables';
import { castArray, castArray as lo_castArray } from 'lodash-es';

export function useUserLite() {
    const {
        isLoggedIn,
        getSession,
        sessionObj: userObj
    } = useCustomAuth();
    const defaultBillingAddressId = computed<Nullable<string>>(() => userObj.value?.defaultBillingAddressUid ?? null);
    const defaultShippingAddressId = computed<Nullable<string>>(() => userObj.value?.defaultShippingAddressUid ?? null);

    const currentMembership = computed<Nullable<Membership>>(() => userObj.value?.currentMembership ?? null);
    /**
    *  @deprecated Use currentMembership
    */
    const selectedMembershipUid = computed<string>(() => userObj.value?.currentMembership?.uid ?? '');
    const currentMembershipUid = selectedMembershipUid;
    const isReseller = computed<boolean>(() => currentMembership.value?.entity?.isRetail ?? false);

    function getCurrentMembership(): Nullable<Membership> {
        return userObj.value?.currentMembership ?? null;
    }

    function isOrgMemberAllowed(
        type: MembershipPermissionsType,
        permission?: Nilish<string>,
        cruds?: MembershipResourceCrudPermission | MembershipResourceCrudPermission[]
    ): boolean {
        const permissions = currentMembership.value?.permissions;
        
        if (!permission || !permissions) {
            return false;
        }

        switch (type) {
            case 'resource':
                const perm = permissions.resources?.[permission]?.permissions;
                
                if (!cruds || !perm) return false;

                cruds = castArray(cruds);
                return cruds.every((crud) => perm.includes(crud));
            case 'page':
                return !!permissions?.pages?.includes(permission);
            case 'flag':
                return !!permissions?.flags?.includes(permission);
        }

        return false;
    }

    function canOrgMemberPerform(
        resource: string,
        action: string | string[]
    ): boolean {
        const permissions = currentMembership.value?.permissions;
        
        if (!permissions) return false;

        const perm = permissions.resources?.[resource]?.actions;
        
        if (!perm) return false;

        action = castArray(action);
        return action.every((act) => perm.includes(act));
    }

    function fetchUser(force = false) {
        return getSession(force);
    }

    function getIsReseller(): boolean {
        const currentMembershipObj = getCurrentMembership();

        if (currentMembershipObj) {
            return currentMembershipObj?.entity?.isRetail ?? false;
        }

        return false;
    }

    return {
        userObj,
        fetchUser,
        isLoggedIn,
        getIsReseller,
        isReseller,
        defaultBillingAddressId,
        defaultShippingAddressId,
        currentMembership,
        currentMembershipUid,
        selectedMembershipUid,
        getCurrentMembership,
        isOrgMemberAllowed,
        canOrgMemberPerform
    };
}
