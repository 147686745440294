import revive_payload_client_doDdZ73f8j from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_iredudflnv3hx4t6fqpbteopae/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7iFqnEK5gY from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_iredudflnv3hx4t6fqpbteopae/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_gdzHIPmk7V from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_iredudflnv3hx4t6fqpbteopae/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7rPsdxl5tl from "/usr/src/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1.79._iqzspcv2lemgzjo2s56e7wcn4u/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_ogceOHE6OZ from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_iredudflnv3hx4t6fqpbteopae/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_xg9WMM5fv4 from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_iredudflnv3hx4t6fqpbteopae/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_cV3xHa5i7F from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_iredudflnv3hx4t6fqpbteopae/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import restore_state_client_TvhlnIXPzx from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_iredudflnv3hx4t6fqpbteopae/node_modules/nuxt/dist/app/plugins/restore-state.client.js";
import chunk_reload_client_G7gmFv4xgP from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_iredudflnv3hx4t6fqpbteopae/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_rhgSd2BMUa from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_iredudflnv3hx4t6fqpbteopae/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _00_init_client_77O4tQBcSo from "/usr/src/app/plugins/00.init.client.ts";
import _01_auth_client_UOBMAirzzr from "/usr/src/app/plugins/01.auth.client.ts";
import _03_vuetify_hLVW487wAp from "/usr/src/app/plugins/03.vuetify.ts";
export default [
  revive_payload_client_doDdZ73f8j,
  unhead_7iFqnEK5gY,
  router_gdzHIPmk7V,
  _0_siteConfig_7rPsdxl5tl,
  payload_client_ogceOHE6OZ,
  navigation_repaint_client_xg9WMM5fv4,
  check_outdated_build_client_cV3xHa5i7F,
  restore_state_client_TvhlnIXPzx,
  chunk_reload_client_G7gmFv4xgP,
  components_plugin_KR1HBZs4kY,
  prefetch_client_rhgSd2BMUa,
  _00_init_client_77O4tQBcSo,
  _01_auth_client_UOBMAirzzr,
  _03_vuetify_hLVW487wAp
]