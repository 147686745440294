import { toPlainObject as lo_toPlainObject } from 'lodash-es';
import { merge as lo_merge } from 'lodash-es';
import { omitBy as lo_omitBy } from 'lodash-es';
import { isUndefined as lo_isUndefined } from 'lodash-es';

const removeExtraProps = (obj: any) => lo_omitBy(obj, (v, k) => k.startsWith('$__meta_') || lo_isUndefined(v));

export class BaseModel<T> {
    id: number | undefined = undefined;
    uid: string | undefined = undefined;

    static new(): InstanceType<typeof this> {
        return new this();
    }

    static getBaseProps() {
        return lo_toPlainObject(new BaseModel());
    }

    static toPlainObject(obj?: any) {
        const inst = new this();
        lo_merge(inst, obj);
        return lo_toPlainObject(inst);
    }

    static removeExtraProps(obj: any): any {
        return removeExtraProps(obj);
    }

    static toPlainFilteredObject<TT = any>(obj: any): TT {
        return lo_toPlainObject(removeExtraProps(obj));
    }

    toPlainObject(): T {
        return lo_toPlainObject(this);
    }

    toPlainFilteredObject(): T {
        return lo_toPlainObject(removeExtraProps(this));
    }

    removeExtraProps(): Partial<this> {
        return removeExtraProps(this);
    }
}
