import type {
    ComposableOptionsBase,
    Cart,
    CartStatus,
    CartShippingStatus,
    CartPaymentStatus,
    CartContactInfo,
    CartAdditionalInfo,
    AddToCartRequest,
    CouponRequest,
    SetShipToAddressRequest,
    StripeShippingObject,
    Shipments,
    SelectedRateRequest,
    Address,
    SiteMembershipContext,
    CreditType
} from '~/types';
import type { NitroFetchOptions } from 'nitropack';
import type { PaymentRequestShippingAddress } from '@stripe/stripe-js';
import type { BaseModel } from '~/models';
import { merge as lo_merge } from 'lodash-es';
import { isError as lo_isError } from 'lodash-es';
import { isEmpty as lo_isEmpty } from 'lodash-es';
import { map as lo_map } from 'lodash-es';
import { toStripeTotal } from '~/utils';
import {
    useAddresses,
    useApiUtils,
    useStripeUtils,
    useCustomFetch,
    useUserLite
} from '~/composables';
import { AddressModel, cartContactInfoProps } from '~/models';


export const cartObj = ref<Cart>();
export const cartStatus = ref<CartStatus>('unloaded');
export const isCartBusy = computed<boolean>(() => ['loading', 'working'].includes(cartStatus.value));
export const cartShippingStatus = ref<CartShippingStatus>('idle');
export const cartPaymentStatus = ref<CartPaymentStatus>('idle');
export const cartLineCount = ref<number>(0);
export const cartItemCount = ref<number>(0);
export const cartTotal = computed<number>(() => cartObj.value?.total ?? 0);
export const canCheckout = computed<boolean>(() => {
    if (!cartObj.value) {
        return false;
    }

    return cartLineCount.value > 0 &&
        cartStatus.value !== 'working';
});


const isFreeOrder = computed<boolean>(() => {
    if (!cartObj.value) {
        return false;
    }

    return cartLineCount.value > 0 && cartObj.value.total <= 0.5;
});
const stripeCartTotal = computed<number>(() => {
    return cartObj.value?.total ? toStripeTotal(cartObj.value?.total) ?? 0 : 0;
});
const stripeClientSecret = computed<Nullable<string>>(() => cartObj.value?.stripeClientSecret ?? null);
const selectedShippingAddress = computed<Nullable<Address>>(() => cartObj.value?.pickupAddress ?? cartObj.value?.shipToAddress ?? null);
const selectedShippingAddressUid = computed<Nullable<string>>(() => selectedShippingAddress.value?.uid ?? null);
const hasShipments = computed<boolean>(() => !!cartObj.value?.shipments?.length);

const shipmentOptions = computed<Nullable<Shipments>>(() => cartObj.value?.shipments ?? null);
const shipmentOptionsSelected = computed<Nilish<SelectedRateRequest>>(() => {
    const result = cartObj.value?.shipments?.reduce((r: SelectedRateRequest, shipment) => {
        if (shipment.selectedRateUid && shipment.uid && shipment.selectedRateUid) {
            r?.push({
                shipmentUid: shipment.uid,
                estimateRateUid: shipment.selectedRateUid
            });
        }

        return r;
    }, []);

    return lo_isEmpty(result) ? null : result;
});

const hasShipmentEstimates = computed<boolean>(() => lo_map(cartObj.value?.shipments, 'estimate').some((o) => !!o));

const requiresShipping = computed<boolean>(() => !!cartObj.value?.requiresShipping);
const isLocalPickup = computed<boolean>(() => !!cartObj.value?.pickupAddress);

const isFulfillmentMethodSelected = computed<boolean>(() => cartObj.value?.isFulfillmentMethodSelected ?? false);
const isStripePaymentConfirmed = ref<boolean>(false);

const stripeCanMakePayment = ref<boolean>(false);
const stripeRedirectUrlBase = ref<string>();

// Top Progress bar for cart
const enableGetCartProgress = false;

const expandOptsGetCart = [
    'applied_coupons',
    'coupons',
    'lines',
    'lines.warranty_claim',
    'lines.body_response',
    'lines.garment_size',
    'lines.sku',
    'lines.sku.images',
    'lines.sku.discount',
    'lines.sku.price',

    'lines.sku.product_path',
    // NOTE: Removed this, probably don't need but verify...
    // 'lines.sku.product',
    'lines.skus.option_uids',
    'lines.sku.options',
    // 'lines.bundle.sku.options',
    // 'lines.bundle.sku.option_uids',
    'lines.bundle.components.sku.options',
    // 'lines.bundle.components.sku.option_uids',
    'lines.bundle',
    'lines.bundle.sku',
    'lines.bundle.components',
    'lines.bundle.components.sku',
    'lines.bundle.components.sku.attribute_values',
    'shipments.estimate',
    'shipments.selected_rate',
    'shipments.vouchers',
    'shipments.lines',
    'pickup_address',
    'ship_to_address'
];


export interface UseCartsOptions extends ComposableOptionsBase {}
export function useCarts(options: Partial<UseCartsOptions> = {}) {
    const route = useRoute();
    const useAddressesObj = useAddresses();
    const useApiUtilsObj = useApiUtils();
    const useStripeUtilsObj = useStripeUtils();
    const useUserObj = useUserLite();
    const apiBasePathCarts = '/v1/carts';
    const expandOpts = { expand: expandOptsGetCart.join(',') };
    const $_fetch = useCustomFetch({ siteContext: options.siteContext });

    const contextQueryParams = computed<Record<string, string> | {}>(() => {
        if (route.matched?.[0]?.name) {
            return {
                context: route.matched[0].name
            };
        }

        return {};
    });
    const selectedStripeShippingAddress = computed<StripeShippingObject | null>(() => {
        return selectedShippingAddress.value
            ? useStripeUtilsObj.toStripeAddress(selectedShippingAddress.value)
            : null;
    });

    async function getCart(cartId = 'current'): Promise<Cart | Error> {
        const url = `${apiBasePathCarts}/${cartId}/`;
        const config: NitroFetchOptions<string> = {
            method: 'GET',
            query: { progress: enableGetCartProgress, ...expandOpts, ...contextQueryParams.value }
        };

        try {
            cartStatus.value = 'loading';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
            return response;
        } catch (err) {
            const errorMsg = useApiUtilsObj.getApiErrorStatus(err);
            cartStatus.value = errorMsg ? 'not-found' : 'error';
            return Error(cartStatus.value);
        }
    }

    async function addToCart(addToCartObj: AddToCartRequest, cartId = 'current'): Promise<void | Error> {
        const url = `${apiBasePathCarts}/${cartId}/add-to-cart/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: addToCartObj,
            query: { ...expandOpts, ...contextQueryParams.value }
        };

        try {
            cartStatus.value = 'working';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
        } catch (err) {
            cartStatus.value = 'loaded';
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function deleteCart(cartId = 'current'): Promise<void | Error> {
        const url = `${apiBasePathCarts}/${cartId}/`;
        const config: NitroFetchOptions<string> = {
            method: 'DELETE',
            query: { ...expandOpts, ...contextQueryParams.value }
        };

        try {
            cartStatus.value = 'working';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
        } catch (err) {
            cartStatus.value = 'loaded';
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function closeCart(cartId = 'current'): Promise<Cart | Error> {
        const url = `${apiBasePathCarts}/${cartId}/close-cart/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: null,
            query: { ...expandOpts, ...contextQueryParams.value }
        };

        if (config.query?.expand) {
            config.query.expand += ',sales_orders';
        }

        try {
            cartStatus.value = 'working';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
            return response;
        } catch (err) {
            cartStatus.value = 'error';
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function removeFromCart(cartLineUid: string, cartId = 'current'): Promise<void | Error> {
        const url = `${apiBasePathCarts}/${cartId}/remove-from-cart/`;
        const config: NitroFetchOptions<string> = {
            method: 'DELETE'
        };

        try {
            cartStatus.value = 'working';
            config.query = lo_merge({}, expandOpts, contextQueryParams.value, { 'uid': cartLineUid });
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
        } catch (err) {
            cartStatus.value = 'loaded';
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function updateCart(values: Partial<Cart> = {}, cartId = 'current'): Promise<void | Error> {
        const url = `${apiBasePathCarts}/${cartId}/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: values,
            query: { ...expandOpts, ...contextQueryParams.value }
        };

        try {
            cartStatus.value = 'working';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
        } catch (err) {
            cartStatus.value = 'loaded';
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function updateLineQty(cartLineUid: string, qty: number, cartId = 'current'): Promise<void | Error> {
        const url = `${apiBasePathCarts}/${cartId}/update-line/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: { qty }
        };

        try {
            cartStatus.value = 'working';
            config.query = lo_merge({}, expandOpts, contextQueryParams.value, { 'line-uid': cartLineUid });
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
        } catch (err) {
            cartStatus.value = 'loaded';
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function applyGiftCard(code: string, unapply = false, cartId = 'current'): Promise<void | Error> {
        const method: string = `${unapply ? 'un' : ''}apply-gift-card`;
        const url = `${apiBasePathCarts}/${cartId}/${method}/`;

        const payload: CouponRequest = { code };
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: payload,
            query: { ...expandOpts, ...contextQueryParams.value }
        };

        try {
            cartStatus.value = 'working';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
        } catch (err) {
            cartStatus.value = 'loaded';
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function applyCoupon(code: string, unapply = false, cartId = 'current'): Promise<void | Error> {
        const method: string = `${unapply ? 'un' : ''}apply-coupon`;
        const url = `${apiBasePathCarts}/${cartId}/${method}/`;

        const payload: CouponRequest = { code };
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: payload,
            query: { ...expandOpts, ...contextQueryParams.value }
        };

        try {
            cartStatus.value = 'working';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
        } catch (err) {
            cartStatus.value = 'loaded';
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function applyCredit(
        unapply = false,
        type: CreditType = 'credit',
        cartId = 'current'
    ): Promise<void | Error> {
        const ep = `apply-${type === 'credit' ? 'account-credit' : 'gift-card-balance'}`;
        const method: string = `${unapply ? 'un' : ''}${ep}`;
        const url = `${apiBasePathCarts}/${cartId}/${method}/`;

        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            query: { ...expandOpts, ...contextQueryParams.value }
        };

        try {
            cartStatus.value = 'working';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
        } catch (err) {
            cartStatus.value = 'loaded';
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function setShipmentOpts(shipOpts: SelectedRateRequest, cartId = 'current'): Promise<void | Error> {
        const url = `${apiBasePathCarts}/${cartId}/select-shipment-rate/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: shipOpts,
            query: { ...expandOpts, ...contextQueryParams.value }
        };

        try {
            cartShippingStatus.value = 'rate-progress';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
            cartShippingStatus.value = 'idle';
        } catch (err) {
            cartShippingStatus.value = 'idle';
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function setShipToAddress(shipToAddressUid: string, cartId = 'current'): Promise<Cart | Error> {
        const url = `${apiBasePathCarts}/${cartId}/ship-to-address/`;

        const payload: SetShipToAddressRequest = {
            shipToAddressUid
        };

        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: payload,
            query: {
                ...expandOpts,
                ...contextQueryParams.value,
                is_after_payment: isStripePaymentConfirmed.value
            }
        };

        try {
            cartShippingStatus.value = 'address-progress';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
            cartShippingStatus.value = 'idle';
            return response;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function setShipToStripeAddress(
        shipToAddress: StripeShippingObject,
        cartId = 'current'
    ): Promise<string | Error> {
        const convertedAddress = useStripeUtilsObj.convertStripeAddress(shipToAddress);
        let addressModel: BaseModel<Address> | AddressModel | Error;

        try {
            if (!convertedAddress) {
                throw new Error('Could not convert address');
            }

            addressModel = await useAddressesObj.createItem(convertedAddress);

            if (lo_isError(addressModel)) {
                throw addressModel;
            }

            const uid = addressModel?.uid;

            if (uid) {
                const response = await setShipToAddress(uid, cartId);

                if (lo_isError(response)) {
                    throw response;
                }

                applyCartObj(response);
                return uid;
            }

            throw new Error('Could not create address');
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function setShipToPaymentReqAddress(
        shipToAddress: PaymentRequestShippingAddress,
        cartId = 'current'
    ): Promise<string | Error> {
        const convertedAddress = useStripeUtilsObj.convertPaymentReqAddress(shipToAddress);
        let addressModel: BaseModel<Address> | AddressModel | Error;

        try {
            if (!convertedAddress) {
                throw new Error('Could not convert address');
            }

            addressModel = await useAddressesObj.createItem(convertedAddress);

            if (lo_isError(addressModel)) {
                throw addressModel;
            }

            const uid = addressModel?.uid;

            if (uid) {
                await setShipToAddress(uid, cartId);
                return uid;
            }

            throw new Error('Could not create address');
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function clearShipToAddress(cartId = 'current'): Promise<void | Error> {
        const url = `${apiBasePathCarts}/${cartId}/ship-to-address/`;
        const config: NitroFetchOptions<string> = {
            method: 'DELETE',
            query: { ...expandOpts, ...contextQueryParams.value }
        };

        try {
            cartShippingStatus.value = 'address-progress';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
            cartShippingStatus.value = 'idle';
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function toggleLocalPickUp(setLocalPickUp?: boolean, cartId = 'current'): Promise<void | Error> {
        const nextState: boolean = setLocalPickUp ?? !cartObj.value?.pickupAddress;
        const op = `${nextState ? 'set' : 'unset'}-local-pickup`;
        const url = `${apiBasePathCarts}/${cartId}/${op}/`;

        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            query: { ...expandOpts, ...contextQueryParams.value }
        };

        try {
            cartShippingStatus.value = 'address-progress';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
            cartShippingStatus.value = 'idle';
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function toggleSavePaymentMethod(setSavePaymentMethod?: boolean, cartId = 'current'): Promise<void | Error> {
        const nextState: boolean = setSavePaymentMethod ?? !cartObj.value?.savePaymentMethod;
        const op = `${nextState ? 'set' : 'unset'}-save-payment-method`;
        const url = `${apiBasePathCarts}/${cartId}/${op}/`;

        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            query: { ...expandOpts, ...contextQueryParams.value }
        };

        try {
            cartPaymentStatus.value = 'save-payment-progress';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
            cartPaymentStatus.value = 'idle';
        } catch (err) {
            cartPaymentStatus.value = 'error';
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function toggleMembershipCtx(type: SiteMembershipContext, cartId = 'current'): Promise<void | Error> {
        const url = `${apiBasePathCarts}/${cartId}/set-${type}-purchase/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            query: { ...expandOpts, ...contextQueryParams.value }
        };

        try {
            cartStatus.value = 'working';
            const response = await $_fetch<Cart>(url, config);
            applyCartObj(response);
        } catch (err) {
            cartStatus.value = 'loaded';
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function setDefaultShippingAddress(): Promise<boolean> {
        let status = false;

        if (!cartObj.value?.shipToAddress) {
            const uid = useUserObj.defaultShippingAddressId.value;

            if (uid) {
                await setShipToAddress(uid);
                status = true;
            }
        }

        return status;
    }

    // Helper Functions

    function applyCartObj(obj: Cart | {}): void {
        if (obj && 'uid' in obj && obj.uid) {
            cartObj.value = obj;
            cartLineCount.value = obj?.lineCount ?? 0;
            cartItemCount.value = obj?.itemCount ?? 0;
            cartStatus.value = 'loaded';
            return;
        }

        cartStatus.value = 'not-found';
    }

    function clearCartObj() {
        cartObj.value = undefined;
        cartStatus.value = 'unloaded';
        cartLineCount.value = 0;
        cartItemCount.value = 0;
        isStripePaymentConfirmed.value = false;
    }

    function checkCartContactInfoObj(cartInfoObj?: Nilish<CartContactInfo>): boolean {
        let obj = cartInfoObj ?? cartObj.value;
        if (obj) {
            return cartContactInfoProps.every((prop) => !!obj?.[prop]);
        }
        return false;
    }

    return {
        cartObj,
        cartStatus,
        cartShippingStatus,
        cartPaymentStatus,
        cartLineCount,
        cartItemCount,
        cartTotal,
        stripeCartTotal,
        requiresShipping,
        isLocalPickup,
        isFulfillmentMethodSelected,
        isStripePaymentConfirmed,
        hasShipments,
        hasShipmentEstimates,
        shipmentOptions,
        shipmentOptionsSelected,
        selectedStripeShippingAddress,
        getCart,
        addToCart,
        closeCart,
        deleteCart,
        removeFromCart,
        applyGiftCard,
        applyCoupon,
        applyCredit,
        updateCart,
        updateLineQty,
        clearCartObj,
        toggleLocalPickUp,
        toggleSavePaymentMethod,
        toggleMembershipCtx,
        setShipmentOpts,
        setShipToAddress,
        clearShipToAddress,
        setShipToStripeAddress,
        setShipToPaymentReqAddress,
        selectedShippingAddress,
        selectedShippingAddressUid,
        stripeCanMakePayment,
        stripeRedirectUrlBase,
        stripeClientSecret,
        canCheckout,
        isFreeOrder,
        setDefaultShippingAddress,
        checkCartContactInfoObj
    };
}
